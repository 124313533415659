import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import logoImage from '../../img/logo.png';

const Register = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState('');
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage('Parollar mos emas');
            setSuccess(false);
            return;
        }

        const formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('password', password);
        if (file) {
            formData.append('passport', file);
        }

        fetch(`https://bot.millatumidi.uz/users?${firstName}&${lastName}&${phone}&${email}&${password}`,{
            method: 'POST',
            body: formData,
        })
            .then(response => {
                if (response.ok) {
                    setMessage('Ro‘yxatdan muvaffaqiyatli o‘tdingiz');
                    setSuccess(true);
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                }
            })
            .catch(error => {
                setMessage(error.message);
                setSuccess(false);
            });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const cancelUpload = () => {
        setFile(null);
        setPreview('');
    };

    return (
        <section className="flex items-center justify-center min-h-screen">
            <div className="w-full max-w-sm px-6 py-8 mx-auto bg-white">
                <div className="flex items-center justify-center mb-6">
                    <img className="max-w-24 h-24" src={logoImage} alt="logo"/>
                </div>
                <h1 className="mb-4 text-xl font-bold text-center text-gray-900">
                    Ro‘yxatdan o‘tish
                </h1>
                {message && (
                    <div
                        className={`text-center p-4 rounded-md ${success ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                        {message}
                    </div>
                )}
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-900">Ism</label>
                        <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            className="block w-full px-4 py-2 mt-2 text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500"
                            placeholder="Ismingiz"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="lastName" className="block text-sm font-medium text-gray-900">Familiya</label>
                        <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            className="block w-full px-4 py-2 mt-2 text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500"
                            placeholder="Familiyangiz"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-900">Telefon
                            raqami</label>
                        <input
                            type="tel"
                            name="phone"
                            id="phone"
                            className="block w-full px-4 py-2 mt-2 text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500"
                            placeholder="+998 XX XXX XX XX"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-900">Elektron
                            pochta</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full px-4 py-2 mt-2 text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500"
                            placeholder="Emailingiz"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-900">Parol</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            className="block w-full px-4 py-2 mt-2 text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500"
                            placeholder="Parolingiz"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-900">Parolni
                            tasdiqlang</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            className="block w-full px-4 py-2 mt-2 text-gray-900 bg-gray-50 border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500"
                            placeholder="Parolingizni qayta kiriting"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="file" className="block text-sm font-medium text-gray-900 py-2">Pasport nusxasi</label>
                        <input
                            type="file"
                            name="file"
                            id="file"
                            className="block w-full text-sm text-gray-900 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                            onChange={handleFileChange}
                            required
                        />
                        {preview && (
                            <div className="mt-4 flex justify-between items-center">
                                <img src={preview} alt="Preview" className="h-24 w-24 object-cover rounded-lg"/>
                                <button
                                    type="button"
                                    className="ml-4 text-white bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    onClick={cancelUpload}>
                                    Bekor qilish
                                </button>
                            </div>
                        )}
                    </div>
                    <button type="submit" style={{ backgroundColor: "#5200FF" }}
                            className="w-full px-4 py-2 text-sm font-medium text-center text-white bg-blue-500 rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-500">
                        Ro‘yxatdan o‘tish
                    </button>
                </form>
                <p className="mt-4 text-center text-sm text-gray-600">
                    Akkauntingiz bormi?{' '}
                    <Link to="/login" style={{ color: "#5200FF" }}>
                        Kirish
                    </Link>
                </p>
            </div>
        </section>
    );
};

export default Register;
