import React, { useEffect, useState, useRef } from 'react';
import BottomNavBar from '../components/navbar';
import Cookies from 'js-cookie';

const ProfilePage = () => {
    const [user, setUser] = useState(null);
    const [cardNumber, setCardNumber] = useState('');
    const [expireDate, setExpireDate] = useState('');
    const [amount, setAmount] = useState('');
    const [smsCode, setSmsCode] = useState(Array(6).fill(''));
    const [showSmsInput, setShowSmsInput] = useState(false);
    const [timer, setTimer] = useState(60);
    const inputsRef = useRef([]);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const formRef = useRef(null);

    const fetchUser = async () => {
        const userId = Cookies.get('userId');
        try {
            const response = await fetch(`https://bot.millatumidi.uz/users?id=${userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'bypass-tunnel-reminder': 'true',
                },
            });
            if (response.ok) {
                const data = await response.json();
                setUser(data);
            } else {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Failed to fetch user:', error);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    const scrollToInput = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const handleCardNumberChange = (e) => {
        const input = e.target.value.replace(/\D/g, '').slice(0, 16);
        const formattedInput = input.replace(/(.{4})/g, '$1 ').trim();
        setCardNumber(formattedInput);
    };

    const handleExpireDateChange = (e) => {
        const input = e.target.value.replace(/\D/g, '').slice(0, 4);
        const formattedDisplay = input.length >= 3 ? `${input.slice(0, 2)}/${input.slice(2, 4)}` : input;
        setExpireDate(input);
    };

    const submitCardInfo = async () => {
        const userId = parseInt(Cookies.get('userId'), 10);

        if (isNaN(userId)) {
            alert('Invalid user ID');
            return;
        }

        try {
            const response = await fetch(`https://bot.millatumidi.uz/api/pay-me/card?userId=${userId}&number=${cardNumber}&expire=${expireDate}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userId,
                    number: cardNumber.replace(/\s/g, ''),
                    expire: expireDate,
                }),
            });

            const data = await response.json();
            if (data.id && data.token) {
                Cookies.set('id', data.id);
                Cookies.set('token', data.token);
            }

            if (response.ok) {
                setMessage("Kard ma'lumotlari kiritildi!");
                setMessageType('success');
                setShowSmsInput(true);
                setTimer(60);
                setTimeout(() => {
                    setMessage('');
                    setMessageType('');
                }, 1500);
            } else {
                throw new Error("Kard ma\'lumotlari noto'g'ri kiritildi!");
            }
        } catch (error) {
            console.error('Error submitting card information:', error);
            setMessageType('error');
            setMessage("Kard ma\'lumotlari noto'g'ri kiritildi!");
        }
    };

    useEffect(() => {
        let interval;
        if (showSmsInput && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setMessage('SMS Kodi eskirdi, qayta kiritishingiz mumkin!');
            setMessageType('error');
            setShowSmsInput(false);
        }
        return () => clearInterval(interval);
    }, [showSmsInput, timer]);

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleSmsCodeChange = (index, event) => {
        const newSmsCode = [...smsCode];
        newSmsCode[index] = event.target.value.slice(0, 1);
        setSmsCode(newSmsCode);

        if (event.target.value !== '' && index < 5) {
            inputsRef.current[index + 1].focus();
        }
    };

    const submitSmsCode = async () => {
        const userId = parseInt(Cookies.get('userId'), 10);
        const code = smsCode.join('');
        const token = Cookies.get('token');
        const id = Cookies.get('id');
        try {
            const response = await fetch(`https://bot.millatumidi.uz/api/pay-me/code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userId,
                    code: code,
                    amount: parseInt(amount),
                    id: id,
                    token: token
                }),
            });

            if (response.ok) {
                setMessage('SMS Kodi muvaffaqiyatli tasdiqlandi!');
                setMessageType('success');
                setShowSmsInput(false);

                await fetchUser();

                setCardNumber('');
                setExpireDate('');
                setTimeout(() => {
                    setMessage('');
                    setMessageType('');
                }, 3000);
            } else {
                throw new Error('SMS Kodi noto\'g\'ri kiritildi!');
            }
        } catch (error) {
            console.error('Error submitting SMS code:', error);
            setMessage('SMS Kodi noto\'g\'ri kiritildi!');
            setMessageType('error');
        }
    };

    const resendCode = () => {
        setMessage('');
        setMessageType('');
        setShowSmsInput(true);
        setTimer(60);
    };

    return (
        <div className="flex flex-col min-h-screen h-full overflow-y-auto">
        <div className="flex-grow p-4 space-y-6 pb-20">
            <h1 className="text-center text-lg font-bold mb-2">Profil</h1>
            {user ? (
                <>
                    <div className="bg-white p-4 rounded-lg shadow-lg space-y-2">
                        <div className="flex justify-between">
                            <p className="text-gray-500">Foydalanuvchi ID Raqami</p>
                            <p className="font-medium">{user.id.toString().padStart(7, '0')}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-gray-500">To'liq ismi</p>
                            <p className="font-medium">{`${user.firstName} ${user.lastName}`}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-gray-500">Email</p>
                            <p className="font-medium">{user.email}</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-gray-500">Telefon raqami</p>
                            <p className="font-medium">{user.phone}</p>
                        </div>
                    </div>

                    <div className="bg-gradient-to-r from-blue-400 to-blue-300 p-4 rounded-lg shadow-lg">
                        <div className="flex justify-between items-center">
                            <div>
                                <p className="text-white text-sm">Hamyon</p>
                                <p className="text-white text-3xl font-bold">{`${user.balance} UZS`}</p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <p className="text-center">No user data found or loading...</p>
            )}

            <div className="flex flex-col space-y-2">
                <label htmlFor="cardNumber" className="text-gray-500">Karta raqami</label>
                <input
                    id="cardNumber"
                    type="text"
                    value={cardNumber}
                    onChange={handleCardNumberChange}
                    onFocus={() => scrollToInput(formRef)}
                    className="form-input px-4 py-2 border rounded-lg"
                    placeholder="0000 0000 0000 0000"
                    maxLength="19"
                />

                <label htmlFor="expireDate" className="text-gray-500">Amal qilish muddati</label>
                <input
                    id="expireDate"
                    type="text"
                    value={expireDate}
                    onChange={handleExpireDateChange}
                    onFocus={() => scrollToInput(formRef)}
                    className="form-input px-4 py-2 border rounded-lg"
                    placeholder="MM/YY"
                    maxLength="5"
                />

                <button
                    onClick={submitCardInfo}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Kiritish
                </button>
            </div>

            {message && (
                <div className={`text-center p-3 rounded ${messageType === 'success' ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
                    {message}
                </div>
            )}
            {showSmsInput ? (
                <div className="flex flex-col space-y-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Summani kiriting
                    </label>
                    <input
                        type="text"
                        value={amount}
                        onChange={handleAmountChange}
                        onFocus={() => scrollToInput(formRef)}
                        className="form-input px-4 py-2 border rounded-lg"
                        placeholder="Amount in UZS"
                    />
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        SMS Kodini kiriting
                    </label>
                    <div className="grid grid-cols-6 gap-2">
                        {smsCode.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onChange={(e) => handleSmsCodeChange(index, e)}
                                onFocus={() => scrollToInput(inputsRef.current[index])}
                                className="form-input px-4 py-2 border rounded-lg text-center"
                                ref={(el) => inputsRef.current[index] = el}
                            />
                        ))}
                    </div>
                    <div className="flex justify-between">
                        <p>Tasdiqlash kodi: {timer} sekund</p>
                        <button
                            onClick={submitSmsCode}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Kodni kiritish
                        </button>
                    </div>
                </div>
            ) : timer === 0 ? (
                <button
                    onClick={resendCode}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Qayta yuborish
                </button>
            ) : null}
            <BottomNavBar />
        </div>
        </div>
    );
};

export default ProfilePage;
